import styled from 'styled-components';
import { stripUnit } from 'polished';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div).attrs((p) => ({
  initial: { scale: 0, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0 },
  transition: { duration: 0.3 },
}))`
  position: absolute;
  bottom: calc(100% + 15px);
  right: 0;
  width: 300px;
  min-height: 200px;

  background: linear-gradient(
      180deg,
      #2561c2 31.04%,
      rgba(37, 114, 194, 0.19) 66.28%,
      rgba(37, 114, 194, 0) 79.89%
    ),
    #ffffff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  transform-origin: 95% 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    margin: ${(p) => p.theme.spacing};
  }
`;

export const Btn = styled.button`
  padding: 5px;
  color: #fff;
  margin-right: ${(p) => stripUnit(p.theme.spacing) - 10}px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  color: #fff;
  align-items: center;

  svg {
    margin: 0 -5px 0 ${(p) => p.theme.spacing};
  }
`;
