import React from 'react';
import Action from 'containers/SupportChat/components/Action';
import { Actions, Text, TextContent, Title, Wrapper } from './styles';
import { useDispatch } from 'react-redux';
import { Actions as ActionsSupport, PAGES } from 'modules/supportChat';
import { getFirstName } from 'lib/text';
import { useCurrentUser } from 'hooks/useCurrentUser';

function Initial() {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <TextContent>
        <Title>Olá {getFirstName(currentUser.name)}!</Title>
        <Text>Como podemos ajudar?</Text>
      </TextContent>

      <Actions>
        <Action
          title="Abrir Central de Ajuda"
          href="https://tecimob.com.br/ajuda/"
          target="_blank"
          onClick={() => {
            dispatch(ActionsSupport.close());
          }}
        />
        <Action
          title="Conversar com suporte"
          onClick={() => {
            dispatch(ActionsSupport.changePage(PAGES.SELECT_SECTOR));
          }}
        />
      </Actions>
    </Wrapper>
  );
}

export default Initial;
