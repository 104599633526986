import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const isActive = test => (match, location) => {
  return location.pathname.includes(test);
};

function ItemNav({
  active,
  href,
  label,
  to,
  exact,
  activeClassName,
  className,
  onClick,
  target,
  rel
}) {
  const Component = to ? NavLink : 'a';

  let props = {
    className,
    onClick
  };

  if (href) {
    props = {
      ...props,
      href,
      target,
      rel
    };
  }

  if (to) {
    props = {
      ...props,
      to,
      exact,
      activeClassName,
      isActive: active && isActive(active)
    };
  }

  return (
    <Component {...props} onClick={onClick}>
      <span className="Menu__link">{label}</span>
    </Component>
  );
}

ItemNav.defaultProps = {
  active: null,
  to: null,
  label: null,
  exact: true,
  activeClassName: 'is-active',
  className: 'Menu__item Menu__item--hover',
  onClick: null
};

ItemNav.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  to: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  exact: PropTypes.bool,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default ItemNav;
