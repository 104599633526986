import { useFeaturedLists } from 'hooks/api/featuredLists';
import { useEffect } from 'react';
import { Select } from 'components/Form';
import { Field } from 'redux-form';

export default function FieldFeaturedLists(props) {
  const { dataFeaturedLists, fetchFeaturedLists } = useFeaturedLists({
    with_all: false,
  });

  useEffect(() => {
    fetchFeaturedLists();
  }, []);

  return (
    <Field
      name="featured_list_id"
      options={dataFeaturedLists.featured_lists}
      valueKey="id"
      labelKey="title"
      component={Select}
      {...props}
    />
  );
}
