import React, { useEffect } from 'react';
import { Text, Title, Wrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, SECTORS_NAME, sectorSelector } from 'modules/supportChat';
import { Field, reduxForm } from 'redux-form';
import { Textarea } from 'components/Form';
import { Row } from 'react-flexbox-grid';
import Button from 'components/Button';
import qs from 'qs';
import { clearPhone } from 'components/ChatBox';
import useFormValue from 'hooks/useFormValue';

export const shareWhatsapp = (number, text = null) => {
  const query = qs.stringify({ text, phone: clearPhone(number) });
  return `https://api.whatsapp.com/send?${query}`;
};

function Form({ handleSubmit, initialize }) {
  const dispatch = useDispatch();
  const sector = useSelector(sectorSelector);
  const text = useFormValue('text');

  useEffect(() => {
    initialize({ sector });
  }, [sector]);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title>Enviar mensagem</Title>
      <Text>Setor selecionado: {SECTORS_NAME[sector]}</Text>
      <Row className="h-margin-top--15">
        <Field
          xs={12}
          name="text"
          type="simple"
          component={Textarea}
          placeholder="Digite sua mensagem"
        />
      </Row>
      <div className="h-flex">
        <span className="h-flex__cell--grow" />
        <Button disabled={!text} type="submit" color="success">
          Enviar
        </Button>
      </div>
    </Wrapper>
  );
}

export default reduxForm({
  form: 'FormChat',
  onSubmit: (values, dispatch, props) => {
    let text = '';

    if (values.sector) {
      text += `*Setor:* ${SECTORS_NAME[values.sector]}\n\n`;
    }

    if (values.text) {
      text += values.text;
    }

    window.open(shareWhatsapp('5548996582208', text), '_blank');

    dispatch(Actions.close());
    return true;
  },
})(Form);
