import React, { useState } from 'react';
import Icon from 'components/Icon';
import { MdArrowDropDown } from 'react-icons/md';
import { Content, Header, Text, Wrapper } from './styles';

function NavGroup({ icon: IconC, text, children }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((visibility) => !visibility);
  };

  return (
    <Wrapper>
      <Header className="NavAside__item" onClick={handleToggle}>
        <span className="NavAside__item_icon">
          {typeof IconC === 'function' ? (
            <IconC className={'Icon Icon--small'} />
          ) : (
            <Icon name={IconC} />
          )}
        </span>{' '}
        <Text>{text}</Text>
        <MdArrowDropDown size={20} />
      </Header>
      <Content isVisible={isVisible}>{children}</Content>
    </Wrapper>
  );
}

export default NavGroup;
