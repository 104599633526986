import getVideoId from 'get-video-id';

export function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();

  let result = str;

  // Troca o final </p> por \n
  result = result.replace(/<\/p\s*[/]?>/gi, '\n');

  // Remove todas as tags e retorna a string
  return result.replace(/(<([^>]+)>)/gi, '');
}

export const normalizeSearch = (text) => {
  return text
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const getInitials = (text) => {
  const initials = text.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const capitalize = (str) => {
  if (!str) return '';
  return str.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

/**
 * Retorna o nome e o sobrenome
 * @param completeName
 * @return {*}
 */
export const getName = (completeName) => {
  if (!completeName) return '';

  const splitedName = capitalize(completeName).split(' ');
  const firstName = splitedName[0];
  const lastName = splitedName.slice(-1)[0];

  if (splitedName.length === 1) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

export const getFirstName = (completeName) =>
  capitalize(completeName).split(' ')[0];

export const plural = (
  quantity,
  singular,
  plural,
  shouldShowQuantity = true
) => {
  let text = shouldShowQuantity ? `${quantity} ` : '';
  text += quantity > 1 ? plural : singular;
  return text;
};

export const normalizeBedrooms = (bedrooms, suites, showDormitory = true) => {
  if (bedrooms <= 0 || suites > bedrooms) return '';

  if (bedrooms <= 0 && suites > 0) return plural(suites, 'Suíte', 'Suítes');

  let bedroomsText = '';
  let suitesText = '';

  if (showDormitory) {
    bedroomsText = plural(bedrooms, 'Dormitório', 'Dormitórios');
  } else {
    bedroomsText = bedrooms;
  }

  if (suites) {
    suitesText = ` (sendo ${plural(suites, 'suíte', 'suítes')})`;
  }

  return bedroomsText + suitesText;
};

export const isValidYoutubeUrl = (url) => {
  try {
    const video = getVideoId(url);

    if (video.service === 'youtube') {
      return video.id;
    }

    return null;
  } catch {
    return null;
  }
};
