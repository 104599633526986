import styled from 'styled-components';

export const Wrapper = styled.form`
  padding: ${(p) => p.theme.spacing};
`;
export const Title = styled.div`
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
`;
export const Text = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 400;
`;
export const Actions = styled.div``;
