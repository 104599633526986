import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { isDirty } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
// Constants
import MODAL_CONFIG from './configs';
import * as MODAL_COMPONENTS from './modals';
// Modules
import { getIsBlocked } from 'modules/login';
import { closeModal as handleClose } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { removeParam } from 'lib/url-helpers';

const defaultProps = {
  modalType: null,
  modalProps: null,
  handleClose: () => {},
};

const propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  handleClose: PropTypes.func,
};

const MODAL_BLOCKED_PERMITTED = [
  'ModalSupport',
  'ModalCreditCard',
  'ModalBankSlip',
  'ModalCreditCardSuccess',
  'ModalBankSlipSuccess',
  'ModalDebitsMonth',
  'ModalDebitsByDay',
  'ModalContract',
  'ModalExtract',
  'ModalPayInstallment',
  'ModalPayment',
  'ModalUpdateContract',
  'ModalUpdateContractDate',
  'ModalPayment2',
  'ModalPayPix',
  'ModalNewFeature',
  'ModalRemoveAccount',
  'ModalInstallmentOpen',
  'ModalSMTPAlert',
];

const ModalRoot = ({
  modal: { modalType, modalProps, modalPosition, modalSize },
  isBlocked,
  handleClose,
}) => {
  //const {  } = this.props;
  const dispatch = useDispatch();
  const hasForm = modalProps?.formName;
  const formDirty = useSelector(isDirty(modalProps?.formName));

  // Verifica se existe um modalType
  // Se não tiver não retorna nenhuma modal
  if (!modalType) return null;

  // Verifica se a modal pode ser
  // renderizada quando estiver bloqueado
  const isModalPermitted = MODAL_BLOCKED_PERMITTED.indexOf(modalType) !== -1;

  // Se a imobiliária estiver bloqueada
  // e se a não estiver permitida retorna nulo
  if (isBlocked && !isModalPermitted) {
    Alert.success('Ação não permitida');

    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return (
    <SpecificModal
      modalConfig={MODAL_CONFIG({ modalPosition, modalSize })}
      modalType={modalType}
      handleClose={(...props) => {
        removeParam('_openModalPerson');
        removeParam('_openModalProperty');

        if (!!hasForm && formDirty) {
          dispatch(
            openConfirmation({
              size: '320px',
              title: 'Deseja realmente sair?',
              text: 'Você ainda não salvou as informações.',
              submitButtonText: 'Sair',
              submitButtonColor: 'success',
              submitButtonColorText: 'white',
              cancelButtonText: 'Não',
              onFinally: () => {
                handleClose();
                if (modalProps.afterClose) modalProps.afterClose(...props);
              },
            })
          );
        } else {
          handleClose();
          if (modalProps.afterClose) modalProps.afterClose(...props);
        }
      }}
      {...modalProps}
    />
  );
};

ModalRoot.defaultProps = defaultProps;
ModalRoot.propTypes = propTypes;

const mapState = (state) => ({
  modal: state.modal,
  isBlocked: getIsBlocked(state),
});

const mapDispathToProps = (dispatch) =>
  bindActionCreators({ handleClose }, dispatch);

export default connect(mapState, mapDispathToProps)(ModalRoot);
