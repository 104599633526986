import React, { useMemo } from 'react';
import { Btn, Header, LogoWrapper, Wrapper } from './styles';
import logo from './logo.svg';
import { MdArrowBack, MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, PAGES, pageSelector } from 'modules/supportChat';
import * as pages from './pages';
import { AnimatePresence, motion } from 'framer-motion';

function Box() {
  const dispatch = useDispatch();
  const page = useSelector(pageSelector);

  const handleClose = () => {
    dispatch(Actions.close());
  };

  // Verifica se é a página inicial
  const isInitialPage = page === 'Initial';

  const handleBack = () => {
    switch (page) {
      case PAGES.SELECT_SECTOR:
        return dispatch(Actions.changePage(PAGES.INITIAL));
      case PAGES.FORM:
        return dispatch(Actions.changePage(PAGES.SELECT_SECTOR));
      default:
        break;
    }
  };

  const renderPage = useMemo(() => {
    try {
      return React.createElement(pages[page]);
    } catch {
      return pages.Initial;
    }
  }, [page]);

  return (
    <Wrapper>
      <Header>
        <LogoWrapper>
          <AnimatePresence>
            {!isInitialPage ? (
              <motion.div
                className="h-pointer"
                initial={{ opacity: 0, x: '-5px' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-5px' }}
              >
                <MdArrowBack size={22} onClick={handleBack} />
              </motion.div>
            ) : null}
          </AnimatePresence>
          <img src={logo} alt="Logo Tecimob" />
        </LogoWrapper>
        <Btn onClick={handleClose}>
          <MdClose size={22} />
        </Btn>
      </Header>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={page}
          initial={{ opacity: 0, x: '-100%' }}
          animate={{ opacity: 1, x: '0%' }}
          exit={{ opacity: 0, x: '100%' }}
          transition={{ duration: 0.3 }}
        >
          {renderPage}
        </motion.div>
      </AnimatePresence>
    </Wrapper>
  );
}

export default Box;
