import React from 'react';
import Action from 'containers/SupportChat/components/Action';
import { Actions, Title, Wrapper } from './styles';
import { useDispatch } from 'react-redux';
import {
  Actions as ActionsSupport,
  SECTORS,
  SECTORS_NAME,
} from 'modules/supportChat';
import _ from 'lodash';

function SelectSector() {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Title>Selecione o setor</Title>

      <Actions>
        {_.map(SECTORS, (sector) => (
          <Action
            title={SECTORS_NAME[sector]}
            onClick={() => {
              dispatch(ActionsSupport.setSector(sector));
              dispatch(ActionsSupport.changePage('Form'));
            }}
          />
        ))}
      </Actions>
    </Wrapper>
  );
}

export default SelectSector;
