import React, { useCallback, useEffect, useState } from 'react';
import {
  Field,
  initialize,
  reduxForm,
  setSubmitFailed,
  stopSubmit,
} from 'redux-form';
import { compose } from 'redux';
import { Col, Row } from 'react-flexbox-grid';

// Components
import { FieldBool, Input, Textarea } from 'components/Form';
import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import ListOptions from 'components/ListOptions';
import ListPortals from './components/ListPortals';

// HOC
import { withId } from '../../HOC/withId';

// Hooks
import { useRequest } from 'hooks/useRequest';

// Images
import sendToOwnerModel from './assets/img/send_to_owner_model.png';
import sendOwnerReportModel from './assets/img/send_owner_report_model.png';

// Services
import * as publishService from 'services/propertiesPartial/publish';
import useFormValue from 'hooks/useFormValue';
import FooterActions from 'pages/PropertyStore/components/FooterActions';
import { openModalProperty } from 'modules/modal';
import Loading from 'components/Loading';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import { useParams } from 'react-router';
import SendToWhatsapp from 'pages/PropertyStore/pages/Publish/components/SendToWhatsapp';
import SendToEmail from './components/SendToEmail';
import Alert from 'components/Alert';
import { useCan } from 'hooks/useCan';
import Nodata from 'components/Nodata';
import RequestPropertyPublication from 'components/RequestPropertyPublication';
import * as financialService from 'services/propertiesPartial/financial';
import Lightbox from 'react-images';
import Seo from './components/Seo';
import { normalizeDate, parseSelect } from 'lib/formHelpers';
import Label from 'components/Label';
import moment from 'moment';
import { isValidDate } from 'lib/date-helpers';
import PreviewStripe from './components/PreviewStripe';
import { Wrapper } from './styles';
import FieldAllStages from 'containers/FieldAllStages';
import FieldSendOwnerReport from './components/FieldSendOwnerReport';
import FieldFeaturedLists from 'containers/FieldFeaturedLists';
import validate from './validate';

const LabelRevision = ({ children, ...props }) => (
  <Label {...props} type="button" className="h-margin-right--5">
    {children}
  </Label>
);

const Publish = ({
  handleSubmit,
  initialize,
  onSubmit,
  submitting,
  permissions,
  change,
  next,
}) => {
  const [hasSeoReception, setHasSeoReception] = useState(false);
  const [isSendToOwnerModelOpen, setIsSendToOwnerModelOpen] = useState(false);
  const [isSendOwnerReportModelOpen, setIsSendOwnerReportModelOpen] =
    useState(false);

  const canPublish = useCan({
    run: 'DYNAMIC_PUBLISH',
    permissions,
  });
  const isPublished = useFormValue('is_published');
  const real_estate_with_guru_portal = useFormValue(
    'real_estate_with_guru_portal'
  );
  const isDraft = useFormValue('is_draft');
  const isFeatured = useFormValue('is_featured');
  const canSendEmail = useFormValue('send_to_owner');
  const should_send_owner_report = useFormValue('should_send_owner_report');
  const without_default_stage_id = useFormValue('without_default_stage_id');
  const is_should_send_owner_report = useFormValue(
    'is_should_send_owner_report'
  );
  const canPublishPortal = useCan({
    run: 'DYNAMIC_PUBLISH_PORTAL',
    permissions,
  });

  const withReview = useFormValue('with_review');

  const { data, fetchData } = useRequest({
    request: publishService.getOne,
    initialFetch: false,
  });

  const {
    data: financial,
    fetchData: fetchFinancial,
    isFetching,
  } = useRequest({
    request: financialService.getOne,
    initialFetch: false,
    initialState: {},
  });

  const { id } = useParams();

  useEffect(() => {
    if (id && canPublish) {
      fetchData(id);
      fetchFinancial(id);
    }
  }, [id, canPublish]);

  useEffect(() => {
    if (isFetching) return;
    initialize({
      ...data,
      send_to_owner: false,
      with_review: !!data.next_review_at,
    });

    if (!data?.meta_description) {
      setHasSeoReception(true);
    } else {
      setHasSeoReception(false);
    }
  }, [data, isFetching]);

  const handleOpenSendToOwnerModel = () => setIsSendToOwnerModelOpen(true);
  const handleCloseSendToOwnerModel = () => setIsSendToOwnerModelOpen(false);

  const handleOpenSendOwnerReportModel = () =>
    setIsSendOwnerReportModelOpen(true);
  const handleCloseSendOwnerReportModel = () =>
    setIsSendOwnerReportModelOpen(false);

  const nextReviewAt = useFormValue('next_review_at');

  const handleAddDays = useCallback(
    (field, days) => () => {
      const date = isValidDate(nextReviewAt)
        ? nextReviewAt
        : moment().format('DD/MM/YYYY');

      change(
        field,
        moment(date, 'DD/MM/YYYY').add(days, 'days').format('DD/MM/YYYY')
      );
    },
    [nextReviewAt]
  );

  if (!canPublish) {
    return (
      <form onSubmit={handleSubmit}>
        <Container className="Container--nopadding h-margin-top--15">
          <ListOptions>
            <Nodata
              title="Você não tem permissão de publicar nos portais"
              text="Solicitar publicação do imóvel aos corretores que tem permissão de publicação"
            >
              <RequestPropertyPublication
                propertyId={id}
                className="h-margin-top--15"
              />
            </Nodata>
          </ListOptions>
        </Container>

        <FooterActions
          isLast
          submitText="Finalizar"
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />
      <MainTitle
        title="Publicação"
        text="Selecione abaixo para quais portais você deseja enviar este imóvel."
      />
      <Container className="Container--nopadding">
        <ListOptions>
          <ListOptions.item
            title="Mostrar imóvel no site?"
            text="Determine se o imóvel será publicado em seu site."
            renderOptions={() => <FieldBool name="is_published" />}
          />
          <ListOptions.item
            title="Mostrar na Página Inicial do site?"
            text="Defina se esse imóvel aparecerá na sessão de “imóveis em destaque” na página inicial do seu site."
            renderOptions={() => <FieldBool name="is_featured" />}
          />
          {isFeatured ? (
            <ListOptions.item
              title="Defina em qual galeria o seu imóvel irá aparecer"
              renderOptions={() => (
                <FieldFeaturedLists style={{ minWidth: '249px' }} />
              )}
            />
          ) : null}

          {isPublished && (
            <ListOptions.item
              title="Tarja do imóvel para o site"
              text="Escolha a cor e a frase que aparecerão na miniatura do imóvel."
              renderOptions={() => <PreviewStripe />}
            />
          )}
        </ListOptions>
      </Container>

      <Container className="Container--nopadding h-margin-top--15">
        <h5 className="h5 ListOptions__title h-padding-top--15 h-padding-left--15">
          Funil do CRM
        </h5>
        <ListOptions>
          <ListOptions.item
            title="Contatos recebidos neste imóvel serão direcionados para o funil e etapa padrão da transação?"
            renderOptions={() => (
              <FieldBool
                name="without_default_stage_id"
                onChange={() => {
                  change('default_stage_id', null);
                }}
              />
            )}
          >
            {!without_default_stage_id && (
              <Row className="h-margin-top--5">
                <Col xs={8} className="h-margin-top--10">
                  Contatos recebidos neste imóvel devem abrir negócio em qual
                  funil e etapa?
                </Col>
                <Col xs={4}>
                  <FieldAllStages
                    name="default_stage_id"
                    parse={parseSelect('stage_id')}
                  />
                </Col>
              </Row>
            )}
          </ListOptions.item>
        </ListOptions>
      </Container>

      <Container className="Container--nopadding h-margin-top--15">
        <h5 className="h5 ListOptions__title h-padding-top--15 h-padding-left--15">
          Relacionado ao proprietário
        </h5>
        <ListOptions>
          {isDraft && (
            <>
              <ListOptions.item
                title="Enviar link da publicação do imóvel para o proprietário?"
                text={
                  <>
                    Avisa o cliente que o imóvel acabou de ser publicado (
                    <span
                      className="h-link"
                      onClick={handleOpenSendToOwnerModel}
                    >
                      Ver modelo
                    </span>
                    ).
                  </>
                }
                renderOptions={() => <FieldBool name="send_to_owner" />}
              >
                {canSendEmail && (
                  <>
                    <Row className="h-margin-top--15">
                      <Field
                        xs={12}
                        type="simple"
                        label="Texto que acompanhará o link do imóvel:"
                        name="email_message"
                        component={Textarea}
                      />
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <SendToWhatsapp />
                        <SendToEmail propertyId={id} />
                      </Col>
                    </Row>
                  </>
                )}
              </ListOptions.item>

              <Lightbox
                images={[{ src: sendToOwnerModel }]}
                isOpen={isSendToOwnerModelOpen}
                onClose={handleCloseSendToOwnerModel}
                backdropClosesModal
              />
            </>
          )}

          <ListOptions.item
            title="Enviar e-mail recorrente para o proprietário?"
            text={
              <>
                Mantem o proprietário atualizado e a comunicação ativa (
                <span
                  className="h-link"
                  onClick={handleOpenSendOwnerReportModel}
                >
                  Ver modelo
                </span>
                ).
              </>
            }
            renderOptions={() => (
              <FieldBool
                name="is_should_send_owner_report"
                onChange={(value) => {
                  if (value === true) {
                    change('should_send_owner_report', 30);
                  }
                }}
              />
            )}
          >
            {is_should_send_owner_report && (
              <Row className="h-margin-top--15">
                <Field
                  xs={4}
                  type="simple"
                  label="Qual e-mail para envio recorrente?"
                  name="email"
                  component={Input}
                />
                <FieldSendOwnerReport />
              </Row>
            )}
          </ListOptions.item>
        </ListOptions>
      </Container>

      <Container className="Container--nopadding h-margin-top--15">
        <Wrapper>
          <ListOptions>
            <ListOptions.item
              title="Gostaria de ser notificado para revisar este cadastro?"
              renderOptions={() => (
                <>
                  <FieldBool
                    responsive={false}
                    name="with_review"
                    className={withReview && 'has-group-next'}
                    onChange={(value) => {
                      if (!value) {
                        change('next_review_at', null);
                      }
                    }}
                  />
                  {withReview && (
                    <div className="h-margin-left--15">
                      <Field
                        label="Próxima data de revisão"
                        name="next_review_at"
                        component={Input}
                        normalize={normalizeDate}
                      />
                      <div className="h-margin-top--10">
                        <LabelRevision
                          handleClick={handleAddDays('next_review_at', 30)}
                        >
                          +30
                        </LabelRevision>
                        <LabelRevision
                          handleClick={handleAddDays('next_review_at', 60)}
                        >
                          +60
                        </LabelRevision>
                        <LabelRevision
                          handleClick={handleAddDays('next_review_at', 90)}
                        >
                          +90
                        </LabelRevision>
                        dias
                      </div>
                    </div>
                  )}
                </>
              )}
            />
          </ListOptions>
        </Wrapper>
      </Container>

      <Lightbox
        images={[{ src: sendOwnerReportModel }]}
        isOpen={isSendOwnerReportModelOpen}
        onClose={handleCloseSendOwnerReportModel}
        backdropClosesModal
      />

      {canPublishPortal && isPublished && (
        <Container className="Container--nopadding h-margin-top--15">
          <ListOptions>
            <ListOptions.item
              title="Portais"
              text="Defina para quais portais você deseja enviar este imóvel:"
            >
              {!isPublished && (
                <Alert color="danger" style={{ margin: '15px 0 10px' }}>
                  Você não pode selecionar portais para este imóvel, pois ele
                  está configurado para não ser mostrado em seu site.
                </Alert>
              )}
              {!financial.is_price_shown && (
                <Alert color="danger" style={{ margin: '15px 0 10px' }}>
                  Este imóvel está marcado para não mostrar preço no site e não
                  será enviado para os portais.
                </Alert>
              )}
              <ListPortals showGuru={real_estate_with_guru_portal} />
            </ListOptions.item>
          </ListOptions>
        </Container>
      )}

      <Seo
        change={change}
        hasSeoReception={hasSeoReception}
        setHasSeoReception={setHasSeoReception}
      />

      {/*{canPublishOtherPortalProperty && canPublishPortalProperty && (*/}

      {/*)}*/}

      <FooterActions
        isLast
        submitText="Finalizar"
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </form>
  );
};

export default compose(
  withId,
  reduxForm({
    form: 'PropertyPublish',
    validate,
    initialValues: {
      can_send_email: false,
      send_to_owner: false,
    },
    onSubmit: (values, d, p) => {
      if (!p.permissions.includes('DYNAMIC_PUBLISH')) {
        return false;
      }
      return publishService.update(values);
    },
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(response?.data);

      p.history.push('/properties/search');

      dispatch(
        openModalProperty({
          property: { id: p.id },
        })
      );
    },
    onSubmitFail: (errors, dispatch, submit, props) => {
      if (errors.featured_list_id) {
        throw errors;
      }

      if (errors.custom_url) {
        dispatch(
          stopSubmit('PropertyPublish', { custom_url: errors?.custom_url })
        );
        return false;
      }

      if (errors.email) {
        dispatch(stopSubmit('PropertyPublish', { email: errors?.email }));
        dispatch(setSubmitFailed('PropertyPublish', 'price'));

        return false;
      }

      dispatch(
        initialize(
          'PropertyPublish',
          {
            can_send_email: false,
            send_to_owner: false,
          },
          false
        )
      );

      if (errors?.price || errors?.transaction) {
        props?.next('financial');
        setTimeout(() => {
          dispatch(stopSubmit('PropertyFinancial', { price: errors?.price }));
          dispatch(setSubmitFailed('PropertyFinancial', 'price'));
        }, 1000);
      }

      if (errors?.neighborhood_id) {
        props?.next('location');
        setTimeout(() => {
          dispatch(
            stopSubmit('PropertyLocation', { neighborhood_id: errors?.price })
          );
          dispatch(setSubmitFailed('PropertyLocation', 'neighborhood_id'));
        }, 1000);
      }
    },
  }),
  withFormConfirmation()
)(Publish);
