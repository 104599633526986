import React from 'react';
import { Wrapper } from './styles';
import { MdOutlineHeadsetMic } from 'react-icons/all';

function Icon({ onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <MdOutlineHeadsetMic size={22} />
    </Wrapper>
  );
}

export default Icon;
