import React, { useEffect, useMemo, useState } from 'react';
import {
  Aside,
  Close,
  Content,
  IconText,
  IconYoutube,
  Link,
  TextWrapper,
  Title,
  Video,
  VideoWrapper,
  Wrapper,
} from './styles';
import { MdOutlineLiveHelp } from 'react-icons/all';
import { useDispatch } from 'react-redux';
import youtubeIcon from './youtube-fill.png';
import { openModalVideo } from 'modules/modal';
import Popover from 'components/Popover';
import FormClose from 'components/CanShowAgain/Form';
import { MdClose } from 'react-icons/md';
import getVideoId from 'get-video-id';

function Item({ topic }) {
  const dispatch = useDispatch();

  const handleOpenVideo = (title, videoUrl, link) => () => {
    dispatch(
      openModalVideo({
        title,
        videoUrl,
        link,
      })
    );
  };

  if (topic.videoUrl) {
    return (
      <li>
        <IconYoutube />
        <Link onClick={handleOpenVideo(topic.text, topic.videoUrl, topic.link)}>
          {topic.text}
        </Link>
      </li>
    );
  }

  return (
    <li>
      <IconText />
      <Link href={topic.link} target="_blank">
        {topic.text}
      </Link>
    </li>
  );
}

function BoxHelp({
  storageKey,
  title = 'Precisa de ajuda?',
  text = 'Separamos alguns tópicos desta área',
  topics,
  mainTopic,
  helpLink = 'https://tecimob.com.br/ajuda/ajuda-integracoes/',
  style,
}) {
  const [canShown, setCanShown] = useState(false);

  const dispatch = useDispatch();

  const canShowBox = useMemo(() => {
    const session = sessionStorage.getItem('@tecimob/box-help/' + storageKey);
    const local = localStorage.getItem('@tecimob/box-help/' + storageKey);

    return !session && !local;
  }, []);

  useEffect(() => {
    setCanShown(canShowBox);
  }, [canShowBox]);

  const mainVideoId = useMemo(() => {
    try {
      return getVideoId(mainTopic?.videoUrl);
    } catch {
      return null;
    }
  }, [mainTopic]);

  const handleSubmit = ({ not_show_again }) => {
    if (not_show_again) {
      localStorage.setItem('@tecimob/box-help/' + storageKey, true);
    } else {
      sessionStorage.setItem('@tecimob/box-help/' + storageKey, true);
    }

    setCanShown(false);
  };

  const handleOpenVideo = (title, videoUrl, link) => () => {
    dispatch(
      openModalVideo({
        title,
        videoUrl,
        link,
      })
    );
  };

  if (!canShown) return false;

  return (
    <Wrapper style={style}>
      <Aside>
        <div>
          <MdOutlineLiveHelp size={100} />
          <p>Ajuda</p>
        </div>
      </Aside>
      <Content>
        <TextWrapper>
          <Title>{title}</Title>
          <p>{text}</p>

          {topics ? (
            <ul>
              {topics.map((topic, key) => (
                <Item key={`topic-${topic.videoUrl}-${key}`} topic={topic} />
              ))}
            </ul>
          ) : null}

          {helpLink && (
            <p>
              Acessar{' '}
              <Link href={helpLink} target="_blank">
                Central de ajuda
              </Link>
            </p>
          )}
        </TextWrapper>
        {mainVideoId?.id ? (
          <VideoWrapper>
            <Title>Vídeo demonstrativo</Title>
            <Video
              onClick={handleOpenVideo(
                mainTopic.text,
                mainTopic.videoUrl,
                mainTopic.link
              )}
            >
              <img
                src={`https://img.youtube.com/vi/${mainVideoId?.id}/0.jpg`}
                alt=""
                className="Video__image"
              />
              <img src={youtubeIcon} alt="" className="Video__icon" />
            </Video>
          </VideoWrapper>
        ) : null}
      </Content>
      <Popover
        width={310}
        initialValues={{ not_show_again: false }}
        onSubmit={handleSubmit}
        component={FormClose}
      >
        <Close>
          <MdClose />
        </Close>
      </Popover>
    </Wrapper>
  );
}

export default BoxHelp;
