import React from 'react';
import DatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';
// Components
import FormGroup from '../FormGroup';
// Helpers
import moment, { format } from 'lib/moment';

const defaultProps = {
  autoFocus: false,
  format: null,
  label: null,
  required: false,
  maxLength: null,
  className: 'FormControl',
  timeFormat: 'HH:mm',
  placeholder: 'Digite...',
};

const propTypes = {};

const $el = document.querySelector('#popper');

const PopperContainer = ({ children }) => ReactDOM.createPortal(children, $el);

function InputDate({
  xs,
  sm,
  md,
  lg,
  label,
  disabled,
  showTimeSelect,
  formGroupClassName,
  required,
  input,
  meta,
  placeholder,
  startDate,
  endDate,
  style,
  styleDate,
  formatDate,
  formatValue,
  ...rest
}) {
  const _format = formatDate
    ? formatDate
    : showTimeSelect
    ? format.datetime
    : format.date;
  const selectedValue = input.value
    ? moment(input.value, formatValue || _format)
    : null;

  function handleChange(date) {
    if (moment(date, _format).isValid()) {
      setTimeout(() => {
        input.onChange(moment(date).format(formatValue || _format));
      }, 50);
    } else {
      setTimeout(() => {
        input.onChange(null);
      }, 50);
    }
  }

  return (
    <FormGroup
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      labelText={label}
      className={formGroupClassName}
      isDisabled={disabled}
      isRequired={required}
      input={input}
      meta={meta}
      style={style}
    >
      <DatePicker
        {...rest}
        {...input}
        showTimeSelect={showTimeSelect}
        startDate={startDate && moment(startDate, _format)}
        endDate={endDate && moment(endDate, _format)}
        disabled={disabled}
        placeholderText={placeholder}
        dateFormat={_format}
        selected={selectedValue}
        onChange={handleChange}
        popperContainer={PopperContainer}
        style={styleDate}
      />
    </FormGroup>
  );
}

InputDate.defaultProps = defaultProps;
InputDate.propTypes = propTypes;

export default InputDate;
