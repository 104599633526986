import React from 'react';
import { Wrapper } from './styles';
import Icon from './components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, isOpenSelector } from 'modules/supportChat';
import Box from './components/Box';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';

function SupportChat() {
  const dispatch = useDispatch();
  const isOpen = useSelector(isOpenSelector);

  const handleOpen = () => {
    dispatch(Actions.open());
  };

  return (
    <ThemeProvider
      theme={{
        spacing: '15px',
        isOpen,
      }}
    >
      <Wrapper>
        <Icon onClick={handleOpen} />
        <AnimatePresence>{isOpen && <Box />}</AnimatePresence>
      </Wrapper>
    </ThemeProvider>
  );
}

export default SupportChat;
