import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  background: #2561c2;
  color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
`;
