import React from 'react';
import { Field } from 'redux-form';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { Wrapper, Label, Content } from './styles';

function RadioListField({ label, options, meta, input, onChange, ...rest }) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Content className="RadioList__content">
        {options.map((option, index) => {
          return (
            <label
              key={index}
              onClick={() => {
                if (onChange) onChange(option.value);
                input.onChange(option.value);
              }}
            >
              {option.value === input.value ? (
                <MdRadioButtonChecked />
              ) : (
                <MdRadioButtonUnchecked />
              )}
              <span>{option.label}</span>
            </label>
          );
        })}
      </Content>
    </Wrapper>
  );
}

function RadioList({ label, options, onChange, ...rest }) {
  return (
    <Field
      {...rest}
      label={label}
      options={options}
      onChange={onChange}
      component={RadioListField}
    />
  );
}

export default RadioList;
