import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Label = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;
export const Content = styled.div`
  width: 100%;
  border: 1px solid #d2dbe3;
  background-color: #f9fbfd;
  padding: 10px;
  border-radius: 4px;

  label {
    white-space: nowrap;
    cursor: pointer;
  }

  span {
    vertical-align: text-bottom;
    position: relative;
    top: 1px;
    margin-left: 5px;
  }

  input {
    display: none;
  }
`;
