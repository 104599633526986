import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 35px;
  right: 20px;
  z-index: 9999;
`;
