import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing};
`;
export const TextContent = styled.div`
  margin-top: 10px;
  margin-bottom: 55px;
`;
export const Title = styled.div`
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
`;
export const Text = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: 400;
`;
export const Actions = styled.div``;
