import React from 'react';
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import { PortalsProvider } from './context';
import AddPortal from './components/AddPortal';
import ListPortals from './components/ListPortals';
import BoxHelp from 'components/BoxHelp';

const Portals = () => {
  return (
    <PortalsProvider>
      <Wrapper>
        <MainTitle
          title="Portais"
          text="Conecte-se aos seus portais favoritos e exporte automaticamente seus imóveis."
        >
          <AddPortal />
        </MainTitle>
        <BoxHelp
          storageKey="portals"
          mainTopic={{
            text: 'Video demonstrativo',
            videoUrl: 'https://www.youtube.com/watch?v=YDnG1SmvXfk',
            link: 'https://tecimob.com.br/ajuda/ajuda-integracoes/',
          }}
          topics={[
            {
              text: 'Conectar a um portal imobiliário',
              videoUrl: 'https://www.youtube.com/watch?v=YDnG1SmvXfk',
              link: 'https://scribehow.com/shared/Integrar_com_Portal_Imobiliario__93FErBw8Qgy-6mL8enW7Og',
            },
            {
              text: 'Selecionar imóveis para enviar pro portal',
              videoUrl: 'https://www.youtube.com/watch?v=dwz5soTr85Y',
              link: 'https://scribehow.com/shared/Marcar_e_desmarcar_imoveis_para_integracao__CugUCZwVQf2D98sgvto9zw',
            },
            {
              text: 'Configurar portal para receber imóveis e enviar leads',
              videoUrl: 'https://www.youtube.com/watch?v=ZjwB7y9e3-g',
              link: 'https://scribehow.com/shared/Copiar_URL_de_imoveis_e_Leads_para_integrar_com_Portal__K7XHv_xhTvW0P-48agCpZQ',
            },
            {
              text: 'Alterar a quantidade de anúncios no Portal',
              videoUrl: 'https://www.youtube.com/watch?v=UDusR7ZAwFQ',
              link: 'https://scribehow.com/shared/Alterar_limite_de_publicacao_do_Portal__EnSLX1uNStSZvZwyV2ySGA',
            },
          ]}
        />
        <ListPortals />
      </Wrapper>
    </PortalsProvider>
  );
};

export default Portals;
