import React from 'react';
// Components
import ItemNav from '../../ItemNav';
import Can from 'containers/Can';

function MenuReports() {
  return (
    <Can run="VIEW_REPORTS">
      <ItemNav label="Relatórios" to="/reports" active="reports" exact />
    </Can>
  );
}

export default MenuReports;
