import React from 'react';
import { Content, Text, Title, Wrapper } from './styles';
import { MdArrowForwardIos } from 'react-icons/all';

function Action({ href, title, text, onClick, ...props }) {
  return (
    <Wrapper as={href ? 'a' : 'div'} href={href} onClick={onClick} {...props}>
      <Content>
        <Title>{title}</Title>
        {text ? <Text>{text}</Text> : null}
      </Content>
      <MdArrowForwardIos className="arrow" />
    </Wrapper>
  );
}

export default Action;
