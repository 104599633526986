import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Helpers
import { parseSelect, parseValueSelect } from 'lib/formHelpers';
// Form Components
import {
  CheckboxGroup,
  Input,
  InputDate,
  SelectMultiple,
} from 'components/Form';
// Components
import Button from 'components/Button';
import Expand from 'components/Expand';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { useCan } from 'hooks/useCan';
import { isSearchingSelector, unsetIsSearching } from 'modules/people';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const PeopleFilterForm = ({
  birthDateGreater,
  birthDateLower,
  updatedLower,
  updatedGreater,
  receptionSources,
  realtors,
  groups,

  // ReduxForm props
  handleSubmit,
  pristine,
  reset,
  isSearching,
  unsetIsSearching,
  handleChangeSearch,
  handleChange,
  handleChangeUpdatedAt,
  handleChangeCreatedAt,
  handleChangeInteraction,
}) => {
  const otherPersonData = useCan({ run: 'OTHER_PERSON_DATA' });

  return (
    <form onSubmit={handleSubmit}>
      <Expand isOpen label="Buscar por">
        <Field
          name="filter.by_search"
          placeholder="Nome, Telefone ou E-mail"
          component={Input}
          onChange={handleChangeSearch}
        />
        <Field
          clearable={false}
          multi={false}
          name="filter.status"
          placeholder="Status"
          component={SelectMultiple}
          options={[
            { label: 'Clientes ativos', value: '1' },
            { label: 'Clientes inativos', value: '0' },
            { label: 'Todos clientes', value: 'ALL_CLIENTS' },
          ]}
          parse={parseSelect('value')}
          onChange={handleChangeInteraction}
        />
      </Expand>

      <Expand isOpen label="Corretor Responsável">
        <Field
          disabled={!otherPersonData}
          creatable={false}
          multi={false}
          name="filter.by_user_id"
          component={SelectMultiple}
          options={realtors}
          labelKey="name"
          valueKey="id"
          parse={(value) => parseValueSelect(value, 'id')}
          onChange={handleChange}
        />
      </Expand>

      <Expand isOpen label="Com interação nos últimos">
        <GroupControl>
          <GroupItem>
            <Field
              type="number"
              name="filter.interacted_last_days"
              component={Input}
              onChange={handleChangeInteraction}
            />
          </GroupItem>
          <GroupItem label="Dias" size="small" />
        </GroupControl>
      </Expand>

      <Expand isOpen label="Data de cadastro entre">
        <GroupControl>
          <GroupItem>
            <Field
              selectsStart
              startDate={updatedGreater}
              endDate={updatedLower}
              format={null}
              name="filter.created_greater_equals"
              component={InputDate}
              onChange={handleChangeCreatedAt}
            />
          </GroupItem>
          <GroupItem>
            <Field
              selectsEnd
              startDate={updatedGreater}
              endDate={updatedLower}
              format={null}
              name="filter.created_lower_equals"
              component={InputDate}
              onChange={handleChangeCreatedAt}
            />
          </GroupItem>
        </GroupControl>
      </Expand>

      <Expand isOpen label="Com interação entre">
        <GroupControl>
          <GroupItem>
            <Field
              selectsStart
              startDate={updatedGreater}
              endDate={updatedLower}
              format={null}
              name="filter.interacted_greater_equals"
              component={InputDate}
              onChange={handleChangeUpdatedAt}
            />
          </GroupItem>
          <GroupItem>
            <Field
              selectsEnd
              startDate={updatedGreater}
              endDate={updatedLower}
              format={null}
              name="filter.interacted_lower_equals"
              component={InputDate}
              onChange={handleChangeUpdatedAt}
            />
          </GroupItem>
        </GroupControl>
      </Expand>

      <Expand isOpen label="Tipo do cliente">
        <CheckboxGroup
          limit={5}
          name="filter.where_group_id"
          options={groups}
          valueKey="id"
          labelKey="name"
          onChange={handleChange}
        />
      </Expand>

      {receptionSources && (
        <Expand isOpen label="Origem da captação">
          <CheckboxGroup
            limit={5}
            name="filter.reception_source_id"
            options={receptionSources}
            valueKey="id"
            labelKey="name"
            onChange={handleChange}
          />
        </Expand>
      )}

      <Expand isOpen label="Aniversariantes entre">
        <GroupControl>
          <GroupItem>
            <Field
              selectsStart
              startDate={birthDateGreater}
              endDate={birthDateLower}
              format={null}
              name="filter.birth_date_greater"
              component={InputDate}
              onChange={handleChange}
            />
          </GroupItem>
          <GroupItem>
            <Field
              selectsEnd
              startDate={birthDateGreater}
              endDate={birthDateLower}
              format={null}
              name="filter.birth_date_lower"
              component={InputDate}
              onChange={handleChange}
            />
          </GroupItem>
        </GroupControl>
      </Expand>

      {isSearching && (
        <div
          style={{
            position: 'sticky',
            padding: '15px 0',
            bottom: '-20px',
            background: '#FFFFFF',
          }}
        >
          <Button
            block
            type="button"
            onClick={() => {
              reset();
              setTimeout(() => {
                unsetIsSearching();
              }, 900);
            }}
            color="danger"
          >
            Limpar Filtro
          </Button>
        </div>
      )}
    </form>
  );
};

PeopleFilterForm.defaultProps = defaultProps;
PeopleFilterForm.propTypes = propTypes;

export const selector = formValueSelector('PeopleFilterForm');

const mapStateToProps = (state) => ({
  isSearching: isSearchingSelector(state),
  birthDateLower: selector(state, 'filter.birth_date_lower'),
  birthDateGreater: selector(state, 'filter.birth_date_greater'),
  updatedLower: selector(state, 'filter.interacted_lower_equals'),
  updatedGreater: selector(state, 'filter.interacted_greater_equals'),
  createdLower: selector(state, 'filter.created_lower_equals'),
  createdGreater: selector(state, 'filter.created_greater_equals'),
});

const mapDispatchToProps = {
  unsetIsSearching,
};

const PeopleFilterFormWrapper = reduxForm({
  form: 'PeopleFilterForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props, prevValues) => {
    // if (_.isEmpty(prevValues) || _.isEqual(values.filter, prevValues.filter))
    //   return;
    //
    // clearTimeout(timeout);
    // timeout = setTimeout(() => props.submit(), 300);
  },
})(PeopleFilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleFilterFormWrapper);
