import React from 'react';
import * as pages from 'pages';
import { createRoute } from 'components/SwitchRoutes';
import { withPermission } from 'HOC/withPermission';

// Rotas que tem tanto autenticado como não autenticado
const globalRoutes = [
  createRoute('/termos-de-uso', null, { render: () => <div /> }),
  createRoute('/loading/:token', pages.Loading),
];

// Rotas que tem quando está autenticado
const defaultRoutes = [
  ...globalRoutes,
  createRoute('/dashboard', pages.NewDashboard),
  createRoute('/reception', pages.Reception),
  createRoute('/emails', pages.EmailAccounts),
  createRoute('/integrations', pages.Integrations, { exact: false }),
  createRoute('/users', pages.Users),
  createRoute(
    '/portals',
    withPermission({
      rules: ['EDIT_PORTAL'],
      isPage: true,
    })(pages.Portals)
  ),
  createRoute('/locations', pages.Locations),
  createRoute('/locations/store/:type', pages.RegionPostStore, {
    exact: false,
  }),
  createRoute('/locations/update/:type', pages.RegionPostStore, {
    exact: false,
  }),
  createRoute('/financial', pages.Financial),
  createRoute('/portals/:portalRealEstateId', pages.PortalsOptionSelect),
  createRoute(
    '/portals/:portalRealEstateId/:portalOptionId',
    pages.PropertiesPortal
  ),
  createRoute(
    '/reports',
    withPermission({
      rules: ['VIEW_REPORTS'],
      isPage: true,
    })(pages.Reports),
    { exact: false }
  ),
  createRoute('/help', pages.Help, { exact: false }),
  createRoute('/config', pages.Config, { exact: false }),
  createRoute('/no-permission/:action?', pages.NoPermission),
  createRoute('/schedule', pages.Schedule),
  createRoute('/tests', pages.Tests, { exact: false }),
  createRoute('/recommendations', pages.Recommendations),
];

// Rotas relacionadas a imóvel
const propertiesRoutes = [
  createRoute('/properties/dashboard', pages.Properties),
  createRoute('/properties/search', pages.PropertiesSearch),
  createRoute('/properties/condos', pages.PropertiesCondos),
  createRoute('/properties/show', pages.Properties),
  createRoute('/properties/show/:id', pages.PropertyShow),
  createRoute('/properties/show/:status?', pages.Properties),
  createRoute('/properties/portal/:portalRealestateId', pages.PropertiesPortal),

  createRoute('/properties/store', pages.PropertyStore, {
    exact: false,
  }),
  createRoute('/properties/update', pages.PropertyStore, {
    exact: false,
  }),

  //createRoute(
  //  '/properties/store',
  //  withPermission({
  //    rules: [myself.properties.register],
  //    isPage: true
  //  })(pages.PropertyAdd)
  //),
  //createRoute('/properties/update/:property_id', pages.PropertyAdd),
  createRoute(
    '/properties/update/:property_id/gallery/:gallery',
    pages.PropertiesGallery
  ),
  createRoute('/properties/alter-price', pages.PropertyAlterPrice, {
    exact: false,
  }),
  createRoute('/properties/rented', pages.PropertiesRented),
  createRoute('/properties/sold', pages.PropertiesSold),
  createRoute('/properties/excluded', pages.PropertiesExcluded),
  createRoute('/properties/booked', pages.PropertiesBooked),
  createRoute('/properties/revision/show', pages.PropertiesRevision),
];

// Rotas relacionadas ao condomínio
const condoRoutes = [
  createRoute('/condos/store', pages.CondoStore, {
    exact: false,
  }),
  createRoute('/condos/update', pages.CondoStore, {
    exact: false,
  }),
];

// Rotas relacionadas a perfil de interesse
const profileRoutes = [
  createRoute('/profile/create', pages.Profile),
  createRoute('/profile/people', pages.PeopleSearchProfile),
  createRoute('/profile/search', () =>
    React.createElement(pages.Profile, { isSearching: true })
  ),
  createRoute('/profile/update/:person_id/:profile_id', pages.Profile),
];

// Rotas relacionadas a plano
export const planRoutes = [
  createRoute(
    '/plan/_history',
    withPermission({
      rules: ['REAL_ESTATE_FINANCIAL'],
      isPage: true,
    })(pages.PlanHistory)
  ),
  createRoute(
    '/plan/history',
    withPermission({
      rules: ['REAL_ESTATE_FINANCIAL'],
      isPage: true,
    })(pages.PlanConfig)
  ),
  createRoute(
    '/plan/select/:users?',
    withPermission({
      rules: ['REAL_ESTATE_FINANCIAL'],
      isPage: true,
    })(pages.PlanSelect)
  ),
  createRoute(
    '/plan/contract/:users/:months',
    withPermission({
      rules: ['REAL_ESTATE_FINANCIAL'],
      isPage: true,
    })(pages.PlanContract)
  ),
  createRoute(
    '/plan/installment/:credit_id',
    withPermission({
      rules: ['REAL_ESTATE_FINANCIAL'],
      isPage: true,
    })(pages.PlanInstallment)
  ),
];

export const blockedRoutes = [
  createRoute('/plan/finished', pages.PlanFinished),
  ...planRoutes,
];

// Rotas relacionadas ao CRM
const crmRoutes = [
  createRoute('/crm', pages.Crm, {
    exact: false,
  }),
  createRoute('/crm/matched/show', pages.PropertyMatched),
  createRoute('/crm/matched/show/:property_id', pages.PropertyPeopleMatched),
];

// Rotas relacionadas a clientes
const peopleRoutes = [createRoute('/people/show', pages.People)];

// Rotas relacionadas a site
export const siteRoutes = [
  createRoute('/site', pages.Site, { exact: false }),
  createRoute('/enterprise/store/:id?', pages.EnterpriseStore),
  createRoute('/enterprise', pages.Enterprise),
  createRoute('/featured/:id/property-add', pages.FeaturedPropertyAdd, {
    exact: false,
  }),
  createRoute('/logo/create/:type/:device?', pages.LogoCreate, {
    exact: false,
  }),
];

// Rotas quando não está autenticado
export const notAuthenticatedRoutes = [
  ...globalRoutes,
  createRoute('/', pages.SignIn),
  createRoute('/signup/:email?', pages.SignUp),
  createRoute('/device-logout', pages.DeviceLogout),
  createRoute('/recovery', pages.Recovery),
  createRoute('/recovery/:email/:token', pages.RecoveryToken),
];

// Rotas pra quando o sistema está autenticado
export const authenticatedRoutes = [
  ...defaultRoutes,
  ...propertiesRoutes,
  ...condoRoutes,
  ...profileRoutes,
  ...crmRoutes,
  ...peopleRoutes,
  ...planRoutes,
  ...siteRoutes,
];
