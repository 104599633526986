import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// Modules
import { currentUserSelector, resellerSelector } from 'modules/login';
import {
  openModalContract,
  openModalPayment,
  openModalSupport,
} from 'modules/modal';
// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import Wrapper from 'components/Wrapper';
// Assets
import { shareWhatsapp } from 'components/ChatBox';
import noCredits from './icon-no-credits.png';
import timer from './icon-timer.png';

const Link = ({ children, onClick }) => {
  return (
    <span className="h-link h-pointer" onClick={onClick}>
      {children}
    </span>
  );
};

class PlanFinished extends React.Component {
  handleClickSupport = (e) => {
    e.preventDefault();
    const {
      openModalSupport,
    } = this.props;

    openModalSupport();
  };

  get renderContactPlan() {
    const { suport_email, is_tecimob } = this.props.reseller;

    if (!suport_email || !is_tecimob) return null;

    return (
      <p className="h-color--primary">
        Dúvidas?{' '}
        <a
          className="h-link"
          target="_blank"
          href={shareWhatsapp('554896049333')}
          rel="noreferrer"
        >
          clique aqui
        </a>{' '}
        e entre em contato.
      </p>
    );
  }

  handleContractPlan = (params) => {
    const { openModalPayment } = this.props;
    openModalPayment(params);
  };

  handleSchedule = () => {
    this.handleContractPlan();
  };

  render() {
    const {
      reseller: { is_tecimob },
      currentUser: {
        real_estate: { is_paying },
      },
    } = this.props;

    // Retorna uma tela especial pra quando a revenda não for tecimob
    if (!is_tecimob) {
      return (
        <Wrapper>
          <NoContent
            size={635}
            title="Você está sem créditos."
            text={
              <>
                <Link onClick={this.handleClickSupport}>Entre em contato</Link>{' '}
                com o administrador do site.
              </>
            }
            image={noCredits}
          />
        </Wrapper>
      );
    }

    // Quando a imobiliária ja é recorrente
    // Retorna o aviso de imobiliaria bloqueada por falta de pagamento
    if (is_paying) {
      return (
        <Wrapper>
          <NoContent
            title="Você está sem créditos."
            text="Vamos continuar com essa parceira de sucesso?"
            image={noCredits}
          >
            <Button
              color="success"
              className="h-margin-bottom--15"
              onClick={this.handleContractPlan}
            >
              Atualizar plano
            </Button>
            {this.renderContactPlan}
          </NoContent>
        </Wrapper>
      );
    }

    // Quando a imobiliaria não é recorrente
    return (
      <Wrapper>
        <NoContent
          title="Seu período de testes chegou ao fim"
          text="O que você achou de experimentar os nossos recursos? Vamos continuar com essa parceira de sucesso!"
          image={timer}
        >
          <Button
            onClick={this.handleContractPlan}
            color="success"
            className="h-margin-bottom--15"
          >
            Colocar crédito
          </Button>
          {this.renderContactPlan}
        </NoContent>
      </Wrapper>
    );
  }
}

PlanFinished.defaultProps = {
  currentUser: {
    real_estate: {
      is_paying: false,
      users_count: 0,
    },
  },
  reseller: {
    is_tecimob: false,
    suport_email: null,
  },
  openModalSupport: null,
};

PlanFinished.propTypes = {
  currentUser: PropTypes.shape({
    real_estate: PropTypes.shape({
      is_paying: PropTypes.bool,
      users_count: PropTypes.number,
    }),
  }),
  reseller: PropTypes.shape({
    is_tecimob: PropTypes.bool,
    suport_email: PropTypes.string,
  }),
  openModalSupport: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
  reseller: resellerSelector(state),
});

const mapDispatchToProps = {
  openModalSupport,
  openModalContract,
  openModalPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanFinished);
