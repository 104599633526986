import React from 'react';
import Icon from 'components/Icon';
import { Wrapper } from './styles';

function GroupItem({ to, active, icon: IconC, children }) {
  return (
    <Wrapper to={to} active={active}>
      <span className="NavAside__item_icon">
        {typeof IconC === 'function' ? (
          <IconC className={'Icon Icon--small'} />
        ) : (
          <Icon name={IconC} />
        )}
      </span>{' '}
      {children}
    </Wrapper>
  );
}

export default GroupItem;
