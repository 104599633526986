export default (values) => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (values.is_featured && !values.featured_list_id) {
    errors.featured_list_id = REQUIRED_MSG;
  }

  return errors;
};
