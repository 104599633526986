import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  background: #ffffff;
  border: 1px solid #e0e5eb;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: ${(p) => p.theme.spacing};
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease-in-out;

  .arrow {
    transition: transform 0.2s ease-in-out;
  }

  :hover {
    background-color: #f6f8fd;

    .arrow {
      transform: translateX(5px);
    }
  }
`;

export const Content = styled.div``;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #363636;
`;
export const Text = styled.div`
  font-size: 14px;
  color: #b2b2b2;
  margin-top: 5px;
`;
